import { createContext } from "react";

import {
  GuestPasses,
  UserOrdersPayload,
  MembershipDetails,
  EventSummaryPayload,
} from "../../common/payloads";

export type UserData = {
  guestPasses: GuestPasses;
  orders: UserOrdersPayload;
  user: MembershipDetails;
  events: { admission: EventSummaryPayload[]; other: EventSummaryPayload[] };
  classes: EventSummaryPayload[];
};

type UserContextType = {
  userData: UserData;
  setUserData: React.Dispatch<React.SetStateAction<UserData>>;
  membershipStanding?: string;
  setMembershipStanding?: React.Dispatch<React.SetStateAction<string>>;
  showAlert: boolean;
  setShowAlert: React.Dispatch<React.SetStateAction<boolean>>;
};

export const UserContext = createContext<UserContextType>({
  userData: {
    user: null,
    guestPasses: null,
    orders: null,
    events: null,
    classes: null,
  },
  setUserData: null,
  membershipStanding: null,
  setMembershipStanding: null,
  showAlert: null,
  setShowAlert: null,
});
