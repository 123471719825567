import React, { useCallback, useContext, useState } from "react";
import { Route, Switch } from "react-router-dom";

import { analytics, isSupported, messaging } from "../../initFCM";
import { Announcements } from "../announcements/announcements";
import { EventList } from "../shared/eventList";
import { GuestPasses } from "../ticketing/guestPasses/guestPasses";
import { Home } from "../home/home";
import { Membership } from "../membership/membership";
import { Orders } from "../ticketing/orders/orders";
import { PushNotificationContext } from "../../contexts/pushNotificationContext";
import { Renew } from "../settings/renewal/renew";
import { Settings } from "../settings/settings";
import { SpinnerSuspense } from "../shared/spinner";
import { UserContext } from "../../contexts/userContext";
import { ActiveMemberRoute } from "../activeMemberRoute";
import { AuthContext } from "../../contexts/authContext";
import { NotFound } from "../notFound";
import { generateImgixUrl } from "../../util/generateImgixUrl";

const classesSrc = generateImgixUrl(
  "sharedBackgroundImages/BF594",
  "fit=crop&auto=compress&w=1500&h=350"
);

const eventsSrc = generateImgixUrl(
  "sharedBackgroundImages/BF2044_210209_160235_2",
  "fit=crop&auto=compress&w=1500&h=350"
);

/** Handle messages when the app is in the foreground */
if (isSupported) {
  messaging.onMessage((payload) => {
    navigator.serviceWorker.getRegistration().then((registration) => {
      registration.showNotification(
        payload.notification.title,
        payload.notification
      );
    });
  });
}

const LOCAL_STORAGE_KEY = "notifications";

type UserPageType = {
  fetchUserData: () => void;
};

export const UserPage: React.FC<UserPageType> = ({
  fetchUserData,
}: UserPageType) => {
  const { userData, showAlert, setShowAlert } = useContext(UserContext);
  const { permissions } = useContext(AuthContext);

  // If notifications have not been set yet.
  const [willShowValueProposition, setWillShowValueProposition] = useState(
    Boolean(
      isSupported &&
        Notification &&
        Notification.permission === "default" &&
        localStorage.getItem(LOCAL_STORAGE_KEY) !== "off"
    )
  );

  /** Use Firebase messaging to ask for notification permission */
  const promptForPushNotifications = useCallback(async () => {
    try {
      // For iOS
      if (!Notification) {
        throw new Error("iOS does not support notifications.");
      }

      // First ask for permission, this will prevent further blocking within
      // messaging.getToken, which also requests permission by default.
      await Notification.requestPermission();

      // Hide value proposition after permission has been granted/declined.
      setWillShowValueProposition(false);

      // Get the token from accepting notifications
      // const token = await messaging.getToken();
      await messaging.getToken();

      // Set some of their custom attributes in Firebase Analytics
      const {
        membershipExpirationDate,
        category: membershipCategory,
        program: membershipProgram,
      } = userData.user;
      analytics.setUserProperties({
        membershipExpirationDate,
        membershipCategory,
        membershipProgram,
      });

      localStorage.setItem(LOCAL_STORAGE_KEY, "on");
    } catch (error) {
      console.log(error);
      setWillShowValueProposition(false);
    }
  }, [userData.user]);

  const hidePushNotifications = useCallback(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, "off");

    setWillShowValueProposition(false);
  }, []);

  const onDismissAlert = () => {
    setShowAlert(false);
  };

  return (
    <PushNotificationContext.Provider
      value={{
        willShowValueProposition,
        promptForPushNotifications,
        hidePushNotifications,
      }}
    >
      <Switch>
        <ActiveMemberRoute path="/user/home">
          <Home />
        </ActiveMemberRoute>
        <ActiveMemberRoute path="/user/membership">
          <Membership user={userData.user} />
        </ActiveMemberRoute>
        <ActiveMemberRoute path="/user/admission">
          <EventList
            src={eventsSrc}
            events={userData.events && userData.events.admission}
            rootPath="Book Tickets"
            showAlert={showAlert}
            onDismissAlert={onDismissAlert}
          />
        </ActiveMemberRoute>
        <ActiveMemberRoute path="/user/events">
          <EventList
            src={eventsSrc}
            events={userData.events && userData.events.other}
            rootPath="Events"
            showAlert={showAlert}
            onDismissAlert={onDismissAlert}
          />
        </ActiveMemberRoute>
        {permissions.guestPasses && (
          <ActiveMemberRoute path="/user/guest-passes">
            <GuestPasses guestPasses={userData.guestPasses} />
          </ActiveMemberRoute>
        )}
        {permissions.renewal && (
          <Route path="/user/settings/renew">
            <Renew user={userData.user} fetchUserData={fetchUserData} />
          </Route>
        )}
        <ActiveMemberRoute path="/user/settings">
          <Settings user={userData.user} />
        </ActiveMemberRoute>
        <ActiveMemberRoute path="/user/benefits">
          <Announcements />
        </ActiveMemberRoute>
        <ActiveMemberRoute
          path={[
            "/user/my-orders/:eventId/:orderItemId",
            "/user/my-orders/:event:",
            "/user/my-orders",
          ]}
        >
          <Orders userOrders={userData.orders} />
        </ActiveMemberRoute>
        <ActiveMemberRoute path="/user/classes">
          <EventList
            src={classesSrc}
            events={userData.classes}
            rootPath="Classes"
            showAlert={showAlert}
            onDismissAlert={onDismissAlert}
          />
        </ActiveMemberRoute>
        <ActiveMemberRoute path="*">
          <NotFound />
        </ActiveMemberRoute>
      </Switch>
    </PushNotificationContext.Provider>
  );
};
