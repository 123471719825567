import {
  GuestPass,
  GuestPasses as GuestPassObject,
} from "../../../../common/payloads";
import { Link, Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import React, { useState } from "react";

import { BackLink } from "../backLink";
import { HeroBanner } from "../../shared/heroBanner";
import { TicketCard } from "../../shared/ticketCard";
import { TicketOrder } from "../ticket";
import { generateImgixUrl } from "../../../util/generateImgixUrl";
import classNames from "classnames";
import { RightArrow } from "../../menu/icons";
import { SpinnerSuspense } from "../../shared/spinner";

const backgroundSrc = generateImgixUrl(
  "sharedBackgroundImages/BF221",
  "fit=crop&auto=compress&w=1500&h=350"
);

type GuestPassesProps = { guestPasses: GuestPassObject };

export const GuestPasses: React.FC<GuestPassesProps> = ({
  guestPasses,
}: GuestPassesProps) => {
  const match = useRouteMatch();
  const [selectedGuestPass, setSelectedGuestPass] = useState(null as GuestPass);
  return (
    <Switch>
      <Route path={`${match.url}/:ticket`}>
        <IndividualGuestPass ticket={selectedGuestPass} matchURL={match.url} />
      </Route>
      <Route path={match.url}>
        <GuestPassListView
          guestPasses={guestPasses}
          matchURL={match.url}
          setSelectedGuestPass={setSelectedGuestPass}
        />
      </Route>
    </Switch>
  );
};

type IndividualGuestPassProps = {
  ticket: GuestPass;
  matchURL: string;
};
const IndividualGuestPass: React.FC<IndividualGuestPassProps> = ({
  ticket,
  matchURL,
}: IndividualGuestPassProps) =>
  ticket ? (
    <div className="wrapper" id="guest-pass">
      <HeroBanner header="Member Guest Pass" src={backgroundSrc} />
      <SpinnerSuspense isLoaded={Boolean(ticket)}>
        <BackLink text="Return to all guest passes" />

        <div className="event-items">
          <TicketOrder
            uuid={ticket.uuid}
            eventName={"Guest Pass"}
            subHeader={ticket.id}
            eventDate={ticket.checkInDate}
          />
        </div>
      </SpinnerSuspense>
    </div>
  ) : (
    <Redirect to={matchURL} />
  );

// #TODO => Replace this with response from guestPass object.
const DEFAULT_THUMBNAIL =
  "https://s3-us-west-1.amazonaws.com/acme-demo-images/df7d8c4f-31fb-4460-ac3c-c38f8c1e49fd_PV.png";
type GuestPassListViewProps = {
  guestPasses: GuestPassObject;
  matchURL: string;
  setSelectedGuestPass: React.Dispatch<React.SetStateAction<GuestPass>>;
};
const GuestPassListView: React.FC<GuestPassListViewProps> = ({
  guestPasses,
  matchURL,
  setSelectedGuestPass,
}: GuestPassListViewProps) => {
  const [isShowingExpiredGuestPasses, setIsShowingExpiredGuestPasses] =
    useState(false);

  return (
    <div className="wrapper" id="guest-passes">
      <HeroBanner header="Guest Passes" src={backgroundSrc} />
      <SpinnerSuspense isLoaded={Boolean(guestPasses)}>
        <div className="container">
          <div className="card">
            <div className="card__summary">
              <p>
                Follow these simple steps to redeem guest passes for general
                admission.
                <ol>
                  <li>
                    Go to the&nbsp;
                    <Link className="a-brand-link" to="/user/admission">
                      Book Tickets
                    </Link>
                    &nbsp;page.
                  </li>
                  <li>Select Admission and your desired time.</li>
                  <li>
                    {
                      'Add the number of "Member Guest Pass" tickets you\'d like.'
                    }
                  </li>
                  <li>Complete your order.</li>
                </ol>
              </p>
            </div>
          </div>

          {/** Expired guest passes, only render if expired guest passes exist. */}
          {Boolean(
            guestPasses && guestPasses.active && guestPasses.active.length
          ) ? (
            <div className="card-list__guest-pass">
              {guestPasses.active.map((guestPass) => (
                <Link
                  className="card__guest-pass"
                  key={guestPass.uuid}
                  to={`${matchURL}/${guestPass.uuid}`}
                  onClick={() => setSelectedGuestPass(guestPass)}
                >
                  <TicketCard
                    eventName={guestPass.itemTypeName}
                    eventDate={guestPass.createdOn}
                    ticketItems={[
                      { quantity: 1, displayName: guestPass.itemTypeName },
                    ]}
                    thumbnail={DEFAULT_THUMBNAIL}
                    isList
                  />
                </Link>
              ))}
            </div>
          ) : (
            <p className="order-page__copy">No current guest passes</p>
          )}

          {/** Expired guest passes, only render if expired guest passes exist. */}
          {Boolean(
            guestPasses && guestPasses.checkedIn && guestPasses.checkedIn.length
          ) && (
            <div className="card-list__guest-pass">
              <button
                onClick={() =>
                  setIsShowingExpiredGuestPasses((isShowing) => !isShowing)
                }
                className="event-detail__header card-list__guest-pass__toggle"
              >
                <h3>
                  {isShowingExpiredGuestPasses
                    ? "Hide redeemed guest passes"
                    : "Show redeemed guest passes"}
                </h3>
                <RightArrow
                  className={classNames("event-detail__header-arrow", {
                    "event-detail__header-arrow--active":
                      isShowingExpiredGuestPasses,
                  })}
                />
              </button>
              {isShowingExpiredGuestPasses && (
                <div className="event-detail__item--xl">
                  {guestPasses.checkedIn.map((guestPass) => (
                    <Link
                      className="user-card__link"
                      key={guestPass.uuid}
                      to={`${matchURL}/${guestPass.uuid}`}
                      onClick={() => setSelectedGuestPass(guestPass)}
                    >
                      <TicketCard
                        eventName={guestPass.itemTypeName}
                        eventDate={guestPass.createdOn}
                        ticketItems={[
                          { quantity: 1, displayName: guestPass.itemTypeName },
                        ]}
                        thumbnail={DEFAULT_THUMBNAIL}
                        checkInDate={guestPass.checkInDate}
                        isList
                      />
                    </Link>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </SpinnerSuspense>
    </div>
  );
};
