import React, { createContext } from "react";
import { MembershipPermissionsType } from "../../common/types";
import { PermsGroupType } from "../../common/types";

type AuthContextType = {
  isAuthenticated: boolean;
  setIsAuthenticated?: React.Dispatch<React.SetStateAction<boolean>>;
  permissions: MembershipPermissionsType;
  setPermissions: React.Dispatch<
    React.SetStateAction<MembershipPermissionsType>
  >;
  permsGroup?: PermsGroupType;
  setPermsGroup: React.Dispatch<React.SetStateAction<PermsGroupType>>;
  permsInitialized: boolean; // Whether or not permissions have been fetched from the server
  setPermsInitialized: React.Dispatch<boolean>;
};
export const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  setIsAuthenticated: null,
  permissions: {
    renewal: false,
    guestPasses: false,
    loginLapsed: false,
    loginDropped: false,
  },
  setPermissions: null,
  permsGroup: undefined,
  setPermsGroup: null,
  permsInitialized: false,
  setPermsInitialized: null,
});
