import React, { useState } from "react";

import { Cardholders } from "./cardholder";
import { HeroBanner } from "../shared/heroBanner";
import { MembershipDetails } from "../../../common/payloads";
import { generateImgixUrl } from "../../util/generateImgixUrl";
import { SpinnerSuspense } from "../shared/spinner";

const backgroundSrc = generateImgixUrl(
  "sharedBackgroundImages/BF580_200311_202312",
  "fit=crop&auto=compress&w=1500&h=350"
);

type MembershipProps = { user: MembershipDetails };

/** Membership component. */
export const Membership = ({ user }: MembershipProps) => (
  <div className="wrapper" id="membership">
    <HeroBanner header={`Membership Card`} src={backgroundSrc} />
    <SpinnerSuspense isLoaded={Boolean(user)}>
      {user && (
        <div className="container">
          <Cardholders user={user} membershipId={user.externalMembershipId} />
          <Entitlements user={user} />
          {user.willExpireWithin3Months && (
            <button className="btn-barnes">Renew Membership</button>
          )}
        </div>
      )}
    </SpinnerSuspense>
  </div>
);

const Entitlements: React.FC<MembershipProps> = ({
  user: { benefitDetail },
}: MembershipProps) => {
  const [showMore, setShowMore] = useState(false);
  const benefits = showMore
    ? benefitDetail.benefits
    : benefitDetail.benefits.slice(0, 4);

  return (
    <div className="membership__entitlements">
      <div
        className="membership__entitlements-text"
        dangerouslySetInnerHTML={{
          __html: benefitDetail.description,
        }}
      />

      <ul className="membership__benefits-list">
        {benefits.map(({ description }, i) => (
          <li key={i}>{description}</li>
        ))}
      </ul>
      {benefitDetail.benefits.length > 4 && (
        <div className="membership__ellipsis-button-wrapper">
          <button
            className="membership__ellipsis-button"
            onClick={() => setShowMore((showMore) => !showMore)}
          >
            <span className="membership__ellipsis-button-content">
              {showMore ? "Hide" : "Show More"}
            </span>
          </button>
        </div>
      )}
    </div>
  );
};
