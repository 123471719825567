import { EventItem, OrderPayload } from "acme-ticketing-client";
import { Link, useHistory, useParams, useRouteMatch } from "react-router-dom";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import classNames from "classnames";
import { useAuthRequest, useScrollTop } from "../../../hooks/customHooks";

import { EventItems } from "./eventItems";
import { HeroBanner } from "../../shared/heroBanner";
import { SpinnerSuspense } from "../../shared/spinner";
import { TicketCard } from "../../shared/ticketCard";
import { UserOrdersPayload } from "../../../../common/payloads";
import { getPastOrders } from "../../../services/membershipServices";
import { AuthContext } from "../../../contexts/authContext";
import {
  getContactInfo,
  ContactInfoType,
} from "../../../services/graphCmsService";
import { generateImgixUrl } from "../../../util/generateImgixUrl";
import { RightArrow } from "../../menu/icons";

const backgroundSrc = generateImgixUrl(
  "sharedBackgroundImages/BF412",
  "fit=crop&auto=compress&w=1500&h=350"
);

/**
 * Get thumbnail for specific event.
 * @param {UserOrdersPayload} userOrders - entire orders list for user.
 * @param {OrderPayload} order - specific order to check against.
 * @returns {string} thumbnail s3 src.
 */
const getThumbnail = (userOrders: UserOrdersPayload, order: OrderPayload) =>
  order.eventItems.length &&
  userOrders.images[order.eventItems[0].eventTemplateId] &&
  userOrders.images[order.eventItems[0].eventTemplateId].length
    ? userOrders.images[order.eventItems[0].eventTemplateId][0].preview
    : null;

type OrdersProps = { userOrders: UserOrdersPayload };

export const Orders: React.FC<OrdersProps> = ({ userOrders }: OrdersProps) => {
  useScrollTop();

  const history = useHistory();
  const { eventId, orderItemId } =
    useParams<{ eventId: string; orderItemId: string }>();
  const hasCheckedParams = useRef(false); // Only run effect once per mount.

  const [selectedOrder, setSelectedOrder] = useState(null as OrderPayload);
  const [selectedEventItem, setSelectedEventItem] = useState(null as EventItem);
  const [isOrdersLoaded, setIsOrdersLoaded] = useState(false); // If route has been checked, basically.
  const [isPastOrdersLoaded, setIsPastOrdersLoaded] = useState(false);
  const [pastOrders, setPastOrders] = useState([] as OrderPayload[]);

  const getPastOrdersAuth =
    useAuthRequest<() => Promise<OrderPayload[]>>(cachedGetPastOrders);

  // Fetch past orders
  useEffect(() => {
    const fetchPastOrders = async () => {
      setIsPastOrdersLoaded(false);

      try {
        const pastOrders = await getPastOrdersAuth();
        setPastOrders(pastOrders);
      } catch (e) {
        console.log(e);
      } finally {
        setIsPastOrdersLoaded(true);
      }
    };

    fetchPastOrders();
  }, [getPastOrdersAuth]);

  const updateOrderAndEventItem = useCallback(
    (eventItem: EventItem, order: OrderPayload) => {
      setSelectedEventItem(eventItem);
      setSelectedOrder(order);
    },
    []
  );

  // Check eventId to see if it matches any order
  useEffect(() => {
    // If this effect has not been run yet.
    if (!hasCheckedParams.current && userOrders) {
      hasCheckedParams.current = true;

      // Determine if there is an order that contains an event w/ eventId from route.
      const ordersWithEventItem =
        eventId && userOrders.orders // <= This is the problem line as past orders will not be with it 🏄🏻‍♂️... need to invert control.
          ? userOrders.orders.filter(({ eventItems }) =>
              eventItems.find((eventItem) => eventItem.eventId === eventId)
            )
          : [];

      // Determine if eventItem contains an item w/ orderItemId from route.
      const orderWithEventItemAndOrderItemId = orderItemId
        ? ordersWithEventItem.filter(({ eventItems }) =>
            eventItems.find(({ items }) =>
              items.find((item) => item.orderItemId === orderItemId)
            )
          )
        : [];

      // If we have a valid order, update state hook.
      if (orderWithEventItemAndOrderItemId.length) {
        const selectedOrder = orderWithEventItemAndOrderItemId[0]; // Should be one deep array
        const selectedEventItem = selectedOrder.eventItems.find(({ items }) =>
          items.find((item) => item.orderItemId === orderItemId)
        );

        updateOrderAndEventItem(selectedEventItem, selectedOrder);

        // Otherwise, redirect to root of orders.
      } else {
        history.push("/user/my-orders/");
      }

      setIsOrdersLoaded(true);
    }
  }, [
    eventId,
    orderItemId,
    userOrders,
    pastOrders,
    history,
    updateOrderAndEventItem,
  ]);

  return eventId && selectedEventItem && selectedOrder ? (
    <EventItems
      backLinkOverride="/user/my-orders/"
      eventItem={selectedEventItem}
      order={selectedOrder}
    />
  ) : (
    <OrdersPage
      isLoaded={isOrdersLoaded}
      userOrders={userOrders}
      updateOrderAndEventItem={updateOrderAndEventItem}
      pastOrders={pastOrders}
      isPastOrdersLoaded={isPastOrdersLoaded}
    />
  );
};

/**
 * Cache past orders so on reload orders are in memory and do not need to be requested.
 * @returns {OrderPayload[]} cached past orders from server.
 */
const cachedGetPastOrders: () => Promise<OrderPayload[]> = (() => {
  let pastOrders: OrderPayload[];

  const fetchPastOrders = async () => {
    try {
      const res = await getPastOrders();
      pastOrders = res.orders;
      return pastOrders;
    } catch (e) {
      throw e;
    }
  };

  return async () => pastOrders || fetchPastOrders();
})();

type OrdersPageProps = {
  isLoaded: boolean;
  userOrders: UserOrdersPayload;
  updateOrderAndEventItem: (eventItem: EventItem, order: OrderPayload) => void;
  pastOrders: OrderPayload[];
  isPastOrdersLoaded: boolean;
};

const OrdersPage: React.FC<OrdersPageProps> = ({
  isLoaded,
  userOrders,
  updateOrderAndEventItem,
  pastOrders,
  isPastOrdersLoaded,
}: OrdersPageProps) => {
  const match = useRouteMatch();
  const { permsGroup } = useContext(AuthContext);

  // Show all orders or just current orders.
  const [isShowingPastOrders, setIsShowingPastOrders] = useState(false);
  const [isPastOrdersMounted, setIsPastOrdersMounted] = useState(false);

  // State for Barnes contact info
  const [contactInfo, setContactInfo] = useState<ContactInfoType>();

  // Trigger isMounted after isLoaded has been true, this is so animation fires for past orders.
  useEffect(() => {
    setIsPastOrdersMounted(isPastOrdersLoaded);
  }, [isPastOrdersLoaded]);

  // Fetch Barnes contact info
  useEffect(() => {
    const fetchContactInfo = async () => {
      const contact = await getContactInfo(permsGroup);
      setContactInfo(contact);
    };

    permsGroup && fetchContactInfo();
  }, [permsGroup, setContactInfo]);

  return (
    // If we are not "loaded" (route has not been checked), render nothing
    // This prevents a FOUC where the orders page pops up on render 1,
    // then after route is checked, renders EventItems on useEffect hook from parent.
    <div className="wrapper" id="orders">
      <HeroBanner header="My Orders" src={backgroundSrc} />
      <SpinnerSuspense isLoaded={isLoaded && isPastOrdersLoaded}>
        {!userOrders && !pastOrders ? (
          <div className="container">
            <div className="card">
              <div className="card__summary order-page__text">
                <p>
                  There was an error fetching your orders. You will still
                  receive your tickets via text message on the day of your visit
                  and you can view your order information from the order
                  confirmation emails.
                </p>
                <p>Thank you for your patience while we resolve this issue.</p>
              </div>
            </div>
          </div>
        ) : (
          <div className="container">
            <div className="card">
              <div className="card__summary order-page__text">
                <p>
                  Your orders for admission, events, and classes can be found
                  here. If you need to change or cancel your reservation, please
                  call&nbsp;
                  <a
                    className="a-brand-link"
                    href={`tel:${
                      contactInfo ? contactInfo.phone : "215-278-7100"
                    }`}
                  >
                    {contactInfo ? contactInfo.phone : "215-278-7100"}
                  </a>
                  &nbsp;or email&nbsp;
                  <a
                    className="a-brand-link"
                    href={`mailto:${
                      contactInfo
                        ? contactInfo.email
                        : "members@barnesfoundation.org"
                    }?subject=Order Cancellation`}
                  >
                    {contactInfo
                      ? contactInfo.email
                      : "members@barnesfoundation.org"}
                  </a>
                  .
                </p>
              </div>
            </div>
            {/** Current orders. */}
            {Boolean(
              userOrders && userOrders.orders && userOrders.orders.length
            ) ? (
              userOrders.orders
                .sort((orderA, orderB) => {
                  const dateA = new Date(orderA.eventItems[0].eventDate);
                  const dateB = new Date(orderB.eventItems[0].eventDate);
                  return dateB > dateA ? -1 : dateB < dateA ? 1 : 0;
                })
                .map((order) => (
                  <Order
                    key={order.orderId}
                    order={order}
                    thumbnail={getThumbnail(userOrders, order)}
                    onClick={updateOrderAndEventItem}
                    toBase={match.url}
                  />
                ))
            ) : (
              <p className="order-page__copy order-page__copy--error">
                No current orders
              </p>
            )}

            {/** Past orders, only render if past orders exist. */}
            {pastOrders && (
              <div className="past-orders__container">
                <button
                  onClick={() =>
                    setIsShowingPastOrders((isShowing) => !isShowing)
                  }
                  className="past-orders__toggle"
                >
                  <h3>
                    {isShowingPastOrders ? "Past orders" : "Show past orders"}
                  </h3>
                  <RightArrow
                    className={classNames("event-detail__header-arrow", {
                      "event-detail__header-arrow--active": isShowingPastOrders,
                    })}
                  />
                </button>
                {isShowingPastOrders && (
                  <div
                    className="event-list past-orders__list"
                    style={{
                      opacity: isPastOrdersMounted ? 1 : 0.3,
                      maxHeight: isPastOrdersMounted ? 9999 : 0,
                    }}
                  >
                    {pastOrders.length ? (
                      pastOrders.map((order) => (
                        <Order
                          key={order.orderId}
                          order={order}
                          thumbnail={getThumbnail(userOrders, order)}
                          onClick={updateOrderAndEventItem}
                          toBase={match.url}
                        />
                      ))
                    ) : (
                      <p className="order-page__copy order-page__copy--error">
                        No past orders
                      </p>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </SpinnerSuspense>
    </div>
  );
};

type OrderProps = {
  order: OrderPayload;
  thumbnail: string | void;
  onClick: (eventItem: EventItem, order: OrderPayload) => void;
  toBase: string;
};
// Iterate over each order and display information from eventItem.
const Order: React.FC<OrderProps> = ({
  order,
  thumbnail,
  onClick,
  toBase,
}: OrderProps) => (
  <div className="card">
    {order.eventItems.map((eventItem) => (
      <Link
        className="order-page__card"
        key={eventItem.eventId}
        to={`${toBase}${eventItem.eventId}/${eventItem.items[0].orderItemId}`}
        onClick={() => onClick(eventItem, order)}
      >
        <TicketCard
          eventName={eventItem.eventName}
          eventDate={eventItem.eventDate}
          includeTime={true}
          ticketItems={eventItem.items.map(
            ({ quantity, itemTypeName: displayName }) => ({
              quantity,
              displayName,
            })
          )}
          thumbnail={thumbnail}
          isList
        />
      </Link>
    ))}
  </div>
);
