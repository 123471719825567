import React, { Fragment } from "react";
import { Card } from "./card";
import { EventSummaryPayload } from "../../../common/payloads";
import { HeroBanner } from "../shared/heroBanner";
import { ReactComponent as SadFace } from "./icons/sad-face.svg";
import { useLocation } from "react-router-dom";
import { SpinnerSuspense } from "./spinner";
import { Alert } from "../footer/alert";

type EventsProps = {
  events: EventSummaryPayload[];
  rootPath: string;
  src: string;
  showAlert?: boolean;
  onDismissAlert?: () => void;
};

export const EventList: React.FC<EventsProps> = ({
  events,
  rootPath,
  src,
  showAlert = false,
  onDismissAlert,
}: EventsProps) => {
  const location = useLocation();

  return (
    <div className="wrapper" id={rootPath}>
      <HeroBanner src={src} header={rootPath}></HeroBanner>
      <SpinnerSuspense isLoaded={Boolean(events)}>
        {events && (
          <Fragment>
            {location.pathname.includes("event") && (
              <div className="center">
                <p>
                  View the full calendar of events{" "}
                  <a
                    className="a-brand-link"
                    href="https://www.barnesfoundation.org/whats-on"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </a>
                </p>
              </div>
            )}
            <div className="container">
              <div className="card-list">
                {events.length ? (
                  events.map(({ id, name, images, shortDescription }, i) => (
                    <Card
                      className="card"
                      key={`${i}${rootPath}`}
                      title={name}
                      src={
                        Boolean(images.length && images[0].screen)
                          ? images[0].screen
                          : null
                      }
                      description={shortDescription}
                      to={{
                        pathname: `/event/${id}`,
                        state: {
                          name,
                          html: shortDescription,
                          src:
                            images.length && images[0].screen
                              ? images[0].screen
                              : null,
                        },
                      }}
                    />
                  ))
                ) : (
                  <div className="general__error">
                    <SadFace className="general__sad-face" />
                    <span className="general__error-text">
                      There are currently no available events.
                    </span>
                  </div>
                )}
              </div>
            </div>
          </Fragment>
        )}
      </SpinnerSuspense>
      {showAlert && onDismissAlert && (
        <Alert onDismiss={onDismissAlert}>
          <>
            We’re open Thu–Mon, 11am–5pm;{" "}
            <a
              href="https://www.barnesfoundation.org/plan-your-visit"
              target="_blank"
              rel="noreferrer noopener"
              key="visit-plan"
            >
              plan your visit
            </a>
            <strong key="note">. Note:</strong> The second-floor galleries will
            be closed May 30–Sept. 13 for floor refinishing.
          </>
        </Alert>
      )}
    </div>
  );
};
