import {
  EventDetailPayload,
  MembershipLevelIdentifier,
} from "../../common/payloads";
import React, { useContext, useEffect, useState } from "react";
import { getAdmissionEvent, getMembershipList } from "../services/vipService";

import { EventDetail } from "./eventDetail/eventDetail";
import { Footer } from "./footer/footer";
import { HelmetContext } from "../contexts/helmetContext";
import { ReactComponent as SadFace } from "./shared/icons/sad-face.svg";
import { SpinnerSuspense } from "./shared/spinner";
import { TitleBar } from "./titleBar";
import { useVipContext } from "../contexts/vipContext";

export const Vip = () => {
  const [admissionEventDetail, setAdmissionEventDetail] =
    useState<EventDetailPayload | void>(null);
  const [membershipLevels, setMembershipLevels] = useState<
    MembershipLevelIdentifier[] | void
  >(null);

  // If we have an HTTP error on setup.
  const [isError, setIsError] = useState(false);
  const { showAlert, onDismissAlert } = useVipContext();

  useEffect(() => {
    // Fetch events via VIP API endpoint.
    const fetchAdmission = async () => {
      try {
        const [admissionEvent, memberships] = await Promise.all([
          getAdmissionEvent(),
          getMembershipList(),
        ]);

        setAdmissionEventDetail(admissionEvent);
        setMembershipLevels(memberships);
      } catch (e) {
        console.log(e);
        setIsError(true);
      }
    };

    fetchAdmission();
  }, []);

  // Alter site title tags
  // # TODO => This can be broken into a separate hook that is a value given to
  // the provider in App.tsx.
  const { setHelmetData } = useContext(HelmetContext);
  useEffect(() => {
    setHelmetData({ title: "Plan Your Visit - The Barnes Foundation" });
  }, [setHelmetData]);

  return (
    <>
      <div className="app__content">
        <TitleBar />
        {
          // On error, present sad face. ☹
          !isError ? (
            <SpinnerSuspense isLoaded={Boolean(admissionEventDetail)}>
              <EventDetail
                eventDetailPayload={admissionEventDetail as EventDetailPayload}
                membershipLevels={
                  membershipLevels as MembershipLevelIdentifier[]
                }
                isVip={true}
                showAlert={showAlert}
                onDismissAlert={onDismissAlert}
              />
            </SpinnerSuspense>
          ) : (
            <div className="general__error">
              <SadFace className="general__sad-face" />
              <span className="general__error-text">
                There are currently no available events.
              </span>
            </div>
          )
        }
      </div>
      <Footer />
    </>
  );
};
