import React, { useContext, useEffect, useState } from "react";
import { gql } from "graphql-request";
import parse from "html-react-parser";

import { Card } from "../shared/card";
import { HeroBanner } from "../shared/heroBanner";
import { AuthContext } from "../../contexts/authContext";
import { performGraphCmsRequest } from "../../services/graphCmsService";
import { generateImgixUrl } from "../../util/generateImgixUrl";
import { SpinnerSuspense } from "../shared/spinner";

const backgroundSrc = generateImgixUrl(
  "sharedBackgroundImages/BF1195",
  "fit=crop&auto=compress&w=1500&h=350"
);

export const Announcements: React.FC = () => {
  const [newsItems, setNewsItems] = useState(null);
  const { permsGroup } = useContext(AuthContext);

  useEffect(() => {
    const fetchNewsItems = async () => {
      const query = gql`
        query getNewsItems($permsGroup: [MembershipPermissionLevels!]) {
          newsItems(where: { membershipTypes_contains_some: $permsGroup }) {
            content {
              html
            }
            src {
              url(
                transformation: {
                  image: { resize: { height: 350, width: 480 } }
                }
              )
            }
            title
          }
        }
      `;

      const variables = {
        permsGroup: [permsGroup],
      };

      const { newsItems } = await performGraphCmsRequest(query, variables);

      setNewsItems(newsItems);
    };

    permsGroup && fetchNewsItems();
  }, [setNewsItems, permsGroup]);

  return (
    <div className="wrapper" id="announcements">
      <HeroBanner src={backgroundSrc} header="Benefits"></HeroBanner>
      <SpinnerSuspense isLoaded={Boolean(newsItems)}>
        <div className="container">
          <div className="card-list">
            {newsItems &&
              newsItems.map(({ title, content, src }) => {
                return (
                  <Card
                    key={title}
                    title={title}
                    src={src ? src.url : null}
                    isHTML={true}
                  >
                    {parse(content.html)}
                  </Card>
                );
              })}
          </div>
        </div>
      </SpinnerSuspense>
    </div>
  );
};
