import React, { createContext, useContext, useState } from "react";

type VipContextType = {
  showAlert: boolean;
  setShowAlert: React.Dispatch<React.SetStateAction<boolean>>;
  onDismissAlert: () => void;
};
type ProviderType = {
  children: JSX.Element;
};

const VipContext = createContext<VipContextType | undefined>(undefined);

export const VipContextProvider: React.FC<ProviderType> = ({
  children,
}: ProviderType) => {
  const [showAlert, setShowAlert] = useState(true);
  const onDismissAlert = () => setShowAlert(false);

  const value = {
    showAlert,
    setShowAlert,
    onDismissAlert,
  };

  return <VipContext.Provider value={value}>{children}</VipContext.Provider>;
};

export const useVipContext = (): VipContextType => {
  const content = useContext(VipContext);

  if (!content) {
    throw new Error("Use useVipContext hook inside VipContextProvider.");
  }

  return content;
};
