import React from "react";
import { ReactComponent as CloseIcon } from "../shared/icons/close.svg";

type AlertProps = {
  children: React.ReactNode;
  onDismiss: () => void;
};

export const Alert: React.FC<AlertProps> = ({
  children,
  onDismiss,
}: AlertProps) => {
  return (
    <div className="alert" data-behavior="Alert" role="alert">
      <div className="alert__wrapper">
        <div className="alert__copy">{children}</div>
        <button
          className="alert__btn"
          type="button"
          aria-hidden="true"
          onClick={onDismiss}
        >
          <CloseIcon />
        </button>
      </div>
    </div>
  );
};
