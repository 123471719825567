import { ArtworkOfTheDay } from "./artworkOfTheDay";
import { HeroBanner } from "../shared/heroBanner";
import { HorizontalCard } from "../../components/shared/horizontalCard";
import React, { useEffect, useState } from "react";
import { generateImgixUrl } from "../../util/generateImgixUrl";
import { gql } from "graphql-request";
import { performGraphCmsRequest } from "../../services/graphCmsService";
import parse from "html-react-parser";
import { SpinnerSuspense } from "../shared/spinner";

const backgroundSrc = generateImgixUrl(
  "sharedBackgroundImages/BF388",
  "fit=crop&auto=compress&w=1500&h=350"
);

export const Home = () => {
  const [homePageItems, setHomePageItems] = useState(null);

  useEffect(() => {
    const fetchHomePageItems = async () => {
      const query = gql`
        query getHomePageItems() {
          homeAnnouncements {
            title
            subtitle
            link
            content {
              html
            }
            image {
              url(
                transformation: {
                  image: { resize: { height: 350, width: 480 } }
                }
              )
            }
          }
        }
      `;

      const { homeAnnouncements } = await performGraphCmsRequest(query);

      setHomePageItems(homeAnnouncements);
    };

    fetchHomePageItems();
  }, [setHomePageItems]);

  return (
    <div className="wrapper" id="home">
      <HeroBanner src={backgroundSrc} header="Welcome"></HeroBanner>
      <SpinnerSuspense isLoaded={Boolean(homePageItems)}>
        <div className="container">
          <div className="horizontal-card-list">
            {homePageItems &&
              homePageItems.map(({ title, subtitle, content, link, image }) => {
                return (
                  <HorizontalCard
                    key={title}
                    title={title}
                    subtitle={subtitle}
                    src={image ? image.url : null}
                    link={link}
                  >
                    {parse(content.html)}
                  </HorizontalCard>
                );
              })}
            <ArtworkOfTheDay />
          </div>
        </div>
      </SpinnerSuspense>
    </div>
  );
};
